"use client";

import { ConnectedRouter } from "connected-react-router";
import { useEffect } from "react";
import { Router } from "react-router-dom";

import { setLogLevel } from "@skydio/skybus/src/logger";

import { IS_DEV } from "app/env";
import { history } from "state/history";
import ThemeProvider from "styles/theme";

import type { ReactNode } from "react";

import "i18n";

/** Client layout component for browser router */
const Layout = ({ children }: { children: ReactNode }) => {
  // Enable skybus debug logging in development
  useEffect(() => {
    if (IS_DEV) {
      setLogLevel("debug");
    }
  }, []);

  return (
    <Router history={history}>
      <ConnectedRouter history={history}>
        <ThemeProvider>{children}</ThemeProvider>
      </ConnectedRouter>
    </Router>
  );
};

export default Layout;
